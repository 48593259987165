import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import {
  Layout, Spin, Progress,
  message as Message,
} from 'antd';
import { HomeOptions, FileTree, FileContent } from '../components';
import {
  AppContext,
  MESSAGES,
  MAX_REQUEST_COUNT,
  changeListDataFormat,
  fileToBase64,
  splitBlobFile,
  updateTreeData,
  updateFilePath,
  getFolderList,
  createFolder,
  deleteFolder,
  uploadFile,
  uploadSplitFile,
  uploadMergeFile,
  getFileList,
  deleteFile,
  iconsMap,
  updateActionPermission,
  updateLastActiveTime,
} from '../common';

function Home() {
  const { state, dispatch } = useContext(AppContext);
  const [blobList, setBlobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinningTip, setSpinningTip] = useState(<> </>);
  const [treeData, setTreeData] = useState([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loadedKeys] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesTotal, setFilesTotal] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const isAdmin = state.user && state.user.roles === 'admin';
  const [fileTreeKey, setfileTreeKey] = useState(`${Math.random()}`);
  const [activeFolderNode, setActiveFolderNode] = useState(null);
  const [queryData, setQueryData] = useState({
    pageNum: 0,
    pageSize: 50,
  });
  const [largeFileProgress, setLargeFileProgress] = useState(0);

  const [actionPermission, setActionPermission] = useState({
    blobDelete: false,
    blobUpload: isAdmin || false,
    blobDownload: false,
    folderDelete: false,
    folderUpload: false,
    folderDownload: false,
    onlyBlobActive: false,
  });

  const handleGetFileList = async (params) => {
    try {
      setLoading(true);
      const { data } = await getFileList(params);
      setFiles(data.result);
      setFilesTotal(data.total);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectFolder = async ([selectedKey], { node }) => {
    const pos = node.pos.split('-').slice(1);
    let key = selectedKey;
    if (key === undefined) {
      key = node.id;
    }
    setCurrentPath((origin) => updateFilePath(treeData, pos, origin.slice(0, 1)));
  };

  const handleLoadData = async (item) => {
    try {
      const { root, has_children: hasChildren, key } = item;
      if (!root && !hasChildren) return;
      setLoading(true);
      let list = await getFolderList({ id: key, file: 0 });
      list = changeListDataFormat(list, { iconsMap });
      if (root) {
        setTreeData(list);
      } else {
        setTreeData((origin) => updateTreeData(origin, key, list));
      }
    } finally {
      setLoading(false);
    }
  };

  const getBlobList = async () => {
    const blobs = await getFolderList({ id: 0, file: 0 });
    dispatch({ type: 'blobs', payload: { blobs: changeListDataFormat(blobs) } });
  };

  const handleClickBlob = useCallback(({ key }) => {
    const blob = state.blobs.find((item) => item.key === key);
    if (!blob) {
      return;
    }
    setCurrentPath([blob]);
    handleLoadData({ ...blob, root: true });
    setfileTreeKey(`${Math.random()}`);
  }, [state.blobs]);

  const handleClickPath = ({ index }) => {
    setCurrentPath(currentPath.slice(0, index + 1));
  };

  const handleCreateFolder = async ({ pid, name, node }, callback) => {
    setModalLoading(true);
    try {
      await createFolder({ pid, name });
      Message.success(MESSAGES.SUCCESS.CREATE_SUCCESS);
      // If pid === 0 add blob
      if (pid === 0 || node.type === 'blob') {
        getBlobList();
      }

      if (node) {
        handleLoadData({ ...node, root: node.type === 'blob' });
      }

      if (callback) {
        callback();
      }
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteFolder = async ({ root, blobDeleteId }) => {
    if (!root && currentPath.length < 2) {
      Message.info(MESSAGES.ERROR.SELECT_FOLDER_REQUIRED);
      return;
    }
    try {
      setLoading(true);
      const index = root ? 0 : currentPath.length - 1;
      await deleteFolder({ id: blobDeleteId || currentPath[index].id });
      Message.success(MESSAGES.SUCCESS.DELETE_SUCCESS);
      const newPath = currentPath.slice(0, currentPath.length - 1);
      const updateTreeNode = newPath.slice(-2)[0];
      setCurrentPath(newPath);
      if (updateTreeNode) {
        handleLoadData({ ...updateTreeNode, root: updateTreeNode.type === 'blob' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelectFiles = async (keys) => {
    setSelectedFiles(keys);
  };

  const convertFiles = (fileList) => fileList.map(async (file) => {
    const fileData = await fileToBase64(file);
    return {
      file_name: file.name,
      file_data: fileData,
    };
  });

  const handleSmallFileUpload = async (uploadList, callback) => {
    if (!activeFolderNode) {
      Message.info(MESSAGES.ERROR.UPLOAD_FOLDER_EMPTY);
      return;
    }

    try {
      setLoading(true);
      setSpinningTip(MESSAGES.COMMON.UPLOAD_SNIP_TIPS);
      const filesData = await Promise.all(convertFiles(uploadList));
      const { id } = activeFolderNode;
      await uploadFile({
        folder_id: id,
        files: filesData,
      });
      Message.success(MESSAGES.SUCCESS.UPLOAD_SUCCESS);
      setSelectedFiles([]);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));

      if (callback) {
        callback();
      }
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      }
    } finally {
      setLoading(false);
      setSpinningTip('');
    }
  };

  const loadingWithProgress = (progress = 0) => (
    <>
      <span>{MESSAGES.COMMON.UPLOAD_SNIP_TIPS}</span>
      <div className="large-file-progress">
        <Progress percent={progress} />
      </div>
    </>
  );

  const limitedReqs = ({
    chunks, controller, folderId, fileName, callback,
  }, requestMaxLength = MAX_REQUEST_COUNT) => {
    const chunksLength = chunks.length;

    const chunksGroup = [];
    while (chunks.length) {
      chunksGroup.push(chunks.splice(0, requestMaxLength));
    }

    let reqIndex = 0;
    let uploadedCount = 0;
    const groupLen = chunksGroup.length;
    setLoading(true);
    setSpinningTip(loadingWithProgress());
    const sendGroupReqs = async () => {
      try {
        if (chunksGroup[reqIndex] && chunksGroup[reqIndex].length) {
          await Promise.all(
            chunksGroup[reqIndex].map(async (item) => {
              const fileData = await fileToBase64(item.blob);
              return uploadSplitFile({
                ...item,
                folder_id: folderId,
                file_data: fileData,
              }, controller.signal).then(() => {
                uploadedCount += 1;
                setLargeFileProgress(Math.floor(uploadedCount / chunksLength * 100));
              });
            }),
          );
          updateLastActiveTime();
          reqIndex += 1;
          if (reqIndex === groupLen) {
            await uploadMergeFile({
              folder_id: folderId,
              file_name: fileName,
              block_number: chunksLength,
            });

            setLoading(false);
            setSpinningTip('');
            setLargeFileProgress(0);

            Message.success(MESSAGES.SUCCESS.UPLOAD_SUCCESS);
            setSelectedFiles([]);
            setQueryData((origin) => ({ ...origin, pageNum: 0 }));

            if (callback) {
              callback();
            }
          } else {
            sendGroupReqs();
          }
        } else {
          setLoading(false);
          setSpinningTip('');
          setLargeFileProgress(0);
        }
      } catch (error) {
        if (controller) {
          controller.abort();
        }

        setLoading(false);
        setSpinningTip('');
        setLargeFileProgress(0);

        if (error?.response?.data?.refreshList) {
          setQueryData((origin) => ({ ...origin, pageNum: 0 }));
        }
      }
    };

    sendGroupReqs();
  };

  const handleLargeFileUpload = (file, callback) => {
    if (!activeFolderNode) {
      Message.info(MESSAGES.ERROR.UPLOAD_FOLDER_EMPTY);
      return;
    }

    const controller = new AbortController();
    const { name } = file;
    const { id: folderId } = activeFolderNode;
    const chunks = splitBlobFile(file);

    if (chunks.length) {
      limitedReqs({
        chunks,
        controller,
        folderId,
        fileName: name,
        callback,
      });
    }
  };

  const handleDeleteFiles = async (id) => {
    try {
      setLoading(true);
      await deleteFile(id || selectedFiles.join());
      Message.success(MESSAGES.SUCCESS.DELETE_SUCCESS);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      setSelectedFiles([]);
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setQueryData((origin) => ({
      ...origin,
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
    }));
  };

  useEffect(() => {
    if (largeFileProgress) {
      setSpinningTip(loadingWithProgress(largeFileProgress));
    }
  }, [largeFileProgress]);

  useEffect(() => {
    if (activeFolderNode) {
      const { id, type } = activeFolderNode;
      if (type !== 'folder') return;

      handleGetFileList({
        type: 'operate',
        id,
        ...queryData,
      });
    }
  }, [queryData]);

  useEffect(() => {
    const activeBlob = currentPath[0];
    const activeFolder = currentPath[currentPath.length - 1];

    setSelectedFiles([]);
    setActionPermission(updateActionPermission(activeBlob, activeFolder));

    if (!activeBlob) {
      getBlobList();
    }

    if (activeFolder && activeFolder.type === 'folder') {
      setActiveFolderNode(activeFolder);
      setSelectedKeys([activeFolder.id]);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
    } else {
      setActiveFolderNode(null);
      setSelectedKeys([]);
      setFiles([]);
      setFilesTotal(0);
    }
  }, [currentPath]);

  useEffect(() => {
    const visibleBlobList = [];

    if (state.blobs) {
      state.blobs.forEach((item) => {
        const {
          title,
          upload_permission: uploadPerms,
          view_permission: viewPerms,
        } = item;
        if (uploadPerms || viewPerms) {
          visibleBlobList.push({ ...item, label: title });
        }
      });
    }

    if (visibleBlobList.length && !currentPath.length) {
      handleClickBlob({ key: visibleBlobList[0].id });
    }

    setBlobList(visibleBlobList);
  }, [state.blobs, currentPath]);

  return (
    <Spin spinning={loading} tip={spinningTip}>
      <Layout className="home">
        <HomeOptions
          blobList={blobList}
          paths={currentPath}
          modalLoading={modalLoading}
          actionPermission={actionPermission}
          onClickBlob={handleClickBlob}
          onClickPath={handleClickPath}
          onCreate={handleCreateFolder}
          onDelete={handleDeleteFolder}
        />
        <Layout>
          <FileTree
            treeData={treeData}
            selectedKeys={selectedKeys}
            loadedKeys={loadedKeys}
            onSelect={handleSelectFolder}
            onLoadData={handleLoadData}
            fileTreeKey={fileTreeKey}
          />
          <Layout>
            <FileContent
              files={files || []}
              actionPermission={actionPermission}
              selectedRowKeys={selectedFiles}
              onChange={handleSelectFiles}
              onDelete={handleDeleteFiles}
              onSmallFileUpload={handleSmallFileUpload}
              onLargeFileUpload={handleLargeFileUpload}
              queryData={queryData}
              filesTotal={filesTotal}
              onTableChange={handleTableChange}
              activeFolderNode={activeFolderNode}
              loading={loading}
              spinningTip={spinningTip}
            />
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
}

export default Home;
