import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Layout, Avatar, Dropdown, Menu, Modal,
} from 'antd';
import {
  UserOutlined,
  ExportOutlined,
  DownOutlined,
  HomeOutlined,
  ApartmentOutlined,
  ExclamationCircleOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Logo from '../images/logo.png';
import styles from '../scss/modules/header.module.scss';
import { MESSAGES } from '../common';

const list = [
  {
    key: 'home',
    path: '/home',
    label: <Link to="/">{MESSAGES.HEADER.HOME}</Link>,
    icon: <HomeOutlined />,
  },
  {
    key: 'users',
    path: '/users',
    label: <Link to="/users">{MESSAGES.HEADER.USER}</Link>,
    icon: <UserOutlined />,
  },
  {
    key: 'roles',
    path: '/roles',
    label: <Link to="/roles">{MESSAGES.HEADER.ROLE}</Link>,
    icon: <ApartmentOutlined />,
  },
  {
    key: 'files',
    path: '/files-management',
    label: <Link to="/files-management">{MESSAGES.HEADER.FILE_CONTROL}</Link>,
    icon: <ProfileOutlined />,
  },
  {
    key: 'log',
    path: '/log',
    label: <Link to="/log">{MESSAGES.HEADER.LOG}</Link>,
    icon: <UnorderedListOutlined />,
  },
];

function AppHeader({
  username,
  userRole,
  activePath,
  onLogout,
}) {
  const onClick = () => {
    Modal.confirm({
      title: MESSAGES.HEADER.LOGOUT,
      icon: <ExclamationCircleOutlined />,
      okText: MESSAGES.COMMON.CONFIRM,
      cancelText: MESSAGES.COMMON.CANCEL,
      onOk: () => {
        onLogout();
      },
    });
  };

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          key: 'logout',
          icon: <ExportOutlined />,
          label: MESSAGES.HEADER.LOGOUT,
        },
      ]}
    />
  );

  const menuItems = userRole === 'admin' ? list : list.slice(0, 1);
  const { key: selectedKey = '' } = list.find((item) => item.path === activePath) || {};

  return (
    <Layout.Header>
      <div className={styles.top}>
        <div className={styles.logo}>
          <img src={Logo} alt="iDiscover China Portal" />
        </div>
        <Dropdown
          placement="bottom"
          disabled={!username || !userRole}
          overlay={menu}
        >
          <div>
            <Avatar icon={<UserOutlined />} />
            <span className={styles.name}>{username || '*****'}</span>
            {username && userRole && <DownOutlined />}
          </div>
        </Dropdown>
      </div>
      <Menu
        mode="horizontal"
        items={menuItems}
        selectedKeys={[selectedKey]}
      />
    </Layout.Header>
  );
}

AppHeader.propTypes = {
  username: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  activePath: PropTypes.string.isRequired,
};

export default AppHeader;
