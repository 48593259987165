import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import styles from '../scss/modules/footer.module.scss';

function AppFooter({ footer }) {
  return (
    <Layout.Footer className={styles.footer}>
      <div dangerouslySetInnerHTML={{ __html: footer || 'Copyright' }} />
    </Layout.Footer>
  );
}

export default AppFooter;

AppFooter.propTypes = {
  footer: PropTypes.string.isRequired,
};
