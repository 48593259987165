import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { message as Message } from 'antd';
import {
  MESSAGES, UPLOAD_CHUNK_SIZE, MAX_EXPIRE_TIME,
} from './constants';

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getLocalAccessToken = () => localStorage.getItem('access_token');

export const setLocalAccessToken = (data) => localStorage.setItem('access_token', data);

export const getLocalExpires = () => localStorage.getItem('expires');

export const setLocalExpires = (data) => localStorage.setItem('expires', data);

export const getLocalUserInfo = () => JSON.parse(localStorage.getItem('user_info'));

export const setLocalUserInfo = (data) => localStorage.setItem('user_info', JSON.stringify(data));

export const getLocalCsrfToken = () => localStorage.getItem('csrf_token');

export const setLocalCsrfToken = (data) => localStorage.setItem('csrf_token', data);

export const getLastActiveTime = () => localStorage.getItem('last_active_time');

export const setLastActiveTime = (data) => localStorage.setItem('last_active_time', data);

export const clearLocalStorage = (key) => (
  key ? window.localStorage.removeItem(key) : window.localStorage.clear()
);

// Redirect to SSO
export const redirectToSSO = () => {
  const { REACT_APP_DOMAIN, REACT_APP_CLIENT_ID } = process.env;
  const uri = `${REACT_APP_DOMAIN}/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code`;
  window.location.href = decodeURIComponent(uri);
};

export const loginToAuth = (expired = true) => {
  clearLocalStorage();
  if (expired) {
    Message.error(MESSAGES.ERROR.UNAUTHORIZED);
  }
  redirectToSSO();
};

export const isExpiresIn = () => {
  const expiresIn = getLocalExpires() || 0;
  const token = getLocalAccessToken();
  return expiresIn < Date.now() || !token;
};

let isTimeout = false;
export const checkTimeOut = (...args) => {
  if (isTimeout) {
    return;
  }

  const expireTime = +getLocalExpires() || 0;
  const lastTime = +getLastActiveTime() || 0;
  const currentTime = Date.now();
  const [authLogout, dispatch] = args;

  if ((currentTime > expireTime || (currentTime - lastTime) > MAX_EXPIRE_TIME) && authLogout) {
    isTimeout = true;
    authLogout().then(() => {
      dispatch({ type: 'logout' });
      loginToAuth();
    });
  }
};

export const updateLastActiveTime = () => {
  setLastActiveTime(Date.now());
};

export const updateTreeData = (list, key, children) => list.map((node) => {
  if (node.key === key) {
    return { ...node, children };
  }

  if (node.children) {
    return { ...node, children: updateTreeData(node.children, key, children) };
  }

  return node;
});

export const updateFilePath = (list, pos, res, current = 0) => {
  const index = pos[current];
  const node = current === 0 ? list[index] : list.children[index];
  res.push({
    ...node,
    title: node.title,
    key: node.key,
    id: node.id,
  });
  if (current === pos.length - 1) {
    return res;
  }

  return updateFilePath(node, pos, res, current + 1);
};

export const changeListDataFormat = (list, { iconsMap } = {}) => {
  if (!list) return [];

  return list.map((item) => {
    const {
      name, id, type,
      has_children: hasChildren,
    } = item;
    const opts = {};

    if (type !== 'blob' && !hasChildren) {
      opts.isLeaf = !hasChildren;
    }

    if (iconsMap) {
      opts.icon = ({ expanded }) => {
        const iconType = expanded ? iconsMap.get(`${type}Open`) : iconsMap.get(type);
        return React.createElement(iconType);
      };
    }

    return {
      ...item,
      key: id,
      title: name,
      value: id,
      ...opts,
    };
  });
};

export const fileToBase64 = (file) => new Promise((resolve, _) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(file);
});

export const debounce = (func, time = 300) => {
  let timerId;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    if (timerId) {
      clearTimeout(timerId);
    }
    // eslint-disable-next-line prefer-rest-params
    const lastArguments = arguments;
    timerId = setTimeout(() => {
      func.apply(context, lastArguments);
    }, time);
    return false;
  };
};

export const splitBlobFile = (file) => {
  const chunks = [];
  if (file) {
    const { name, size } = file;
    const chunkSize = UPLOAD_CHUNK_SIZE; // Default is 35M.
    const chunkLength = Math.ceil(size / chunkSize);

    let chunkIndex = 0;
    let start = 0;
    let end = 0;
    while (chunkIndex < chunkLength) {
      end = start + chunkSize;
      if (end > size) {
        end = size;
      }
      const blob = file.slice(start, end);
      chunks.push({
        blob,
        file_name: name,
        file_index: chunkIndex + 1,
      });
      start = end;
      chunkIndex += 1;
    }
  }

  return chunks;
};

export const humanFileSize = (bytes) => {
  const thresh = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(thresh));
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const size = (bytes / (thresh ** i)).toFixed(2) * 1;
  return `${size} ${units[i]}`;
};

export const formatPermissionVal = (optList, valList) => {
  const permissionData = {};
  optList.forEach((element) => {
    const { value } = element;
    permissionData[value] = +valList.includes(value);
  });

  return permissionData;
};

export const updateActionPermission = (blob, folder) => {
  const {
    delete_permission: bDelete,
    upload_permission: bUpload,
    download_permission: bDownload,
  } = blob || {};

  const {
    type: fType,
    delete_permission: fDelete,
    upload_permission: fUpload,
    download_permission: fDownload,
  } = folder || {};

  const onlyBlobActive = fType === 'blob';

  return {
    blobDelete: !!bDelete,
    blobUpload: !!bUpload,
    blobDownload: !!bDownload,
    folderDelete: !!(bDelete || fDelete),
    folderUpload: !!(bUpload || fUpload),
    folderDownload: !!(bDelete || fDownload),
    onlyBlobActive,
  };
};

export const getDownloadLink = (selectedIds = [], files = []) => {
  if (!selectedIds.length || !files.length) {
    return '';
  }

  const ids = [];
  files.forEach((item) => {
    if (selectedIds.includes(`${item.id}`) && !!item.download_permission) {
      ids.push(item.id);
    }
  });

  return ids.length > 0 ? `${process.env.REACT_APP_DOMAIN}/apis/file/${ids.join()}/download` : '';
};

export const getIconByPermission = (permission, iconsMap) => {
  const iconType = +permission ? 'checked' : 'close';
  return React.createElement(iconsMap.get(iconType));
};

export const renderListHtml = (list) => {
  const listContent = list.map((item) => React.createElement('li', {
    key: `${item.name}-${Math.random()}`,
  }, `${item.name}: ${item.reason}`));

  return React.createElement('ul', {
    className: 'api-fail-list',
  }, [...listContent]);
};
