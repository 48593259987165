import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Table, Button, Space, Row, Col,
  Form, Select, DatePicker,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  MESSAGES,
  AppContext,
  getLogList,
} from '../common';

const columns = [
  {
    title: MESSAGES.LABEL.USER,
    dataIndex: 'user',
    key: 'user',
    width: 120,
    align: 'center',
  },
  {
    title: MESSAGES.LOG.TYPE,
    dataIndex: 'type',
    key: 'type',
    width: 120,
    align: 'center',
    render: (text) => MESSAGES.LOG.TYPE_LIST[text.toLocaleUpperCase().replaceAll(' ', '_')],
  },
  {
    title: MESSAGES.LOG.OPERATION,
    dataIndex: 'operation',
    key: 'operation',
    width: 120,
    align: 'center',
    render: (text) => MESSAGES.LOG.ACTIONS[text.toLocaleUpperCase().replaceAll(' ', '_')],
  },
  {
    title: MESSAGES.LOG.DESCRIPTION,
    dataIndex: 'description',
    key: 'description',
    className: 'log-description',
  },
  {
    title: MESSAGES.LOG.CREATE_DATE,
    dataIndex: 'created',
    key: 'created',
    width: 180,
    align: 'center',
  },
];

const logTypeList = [
  {
    name: MESSAGES.LOG.TYPE_LIST.BLOB,
    value: 'blob',
  },
  {
    name: MESSAGES.LOG.TYPE_LIST.FOLDER,
    value: 'folder',
  },
  {
    name: MESSAGES.LOG.TYPE_LIST.FILE,
    value: 'file',
  },
  {
    name: MESSAGES.LOG.TYPE_LIST.FILE_CONTROL,
    value: 'file control',
  },
  {
    name: MESSAGES.LOG.TYPE_LIST.USER,
    value: 'user',
  },
  {
    name: MESSAGES.LOG.TYPE_LIST.PERMISSION,
    value: 'permission',
  },
];

const logOperationList = [
  {
    name: MESSAGES.LOG.ACTIONS.CREATE,
    value: 'create',
  },
  {
    name: MESSAGES.LOG.ACTIONS.UPDATE,
    value: 'update',
  },
  {
    name: MESSAGES.LOG.ACTIONS.DELETE,
    value: 'delete',
  },
  {
    name: MESSAGES.LOG.ACTIONS.DOWNLOAD,
    value: 'download',
  },
];

function Log() {
  const { state } = useContext(AppContext);
  const [form] = Form.useForm();
  const [tableLoading, setTableLoading] = useState(false);
  const [logList, setLogList] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryData, setQueryData] = useState({
    createdStart: '',
    createdEnd: '',
    type: '',
    operation: '',
    pageSize: 50,
    pageNum: 0,
  });
  const navigate = useNavigate();

  const handleGetLogList = async (params) => {
    setTableLoading(true);
    try {
      const { data } = await getLogList(params);
      setLogList(data.result);
      setTotal(data.total);
    } catch (error) {
      // Handle error
    } finally {
      setTableLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    setQueryData((origin) => ({
      ...origin,
      createdStart: '',
      createdEnd: '',
      type: '',
      operation: '',
      pageNum: 0,
    }));
  };

  const onFilterSubmit = (values) => {
    const params = { ...values };
    const { date } = values;
    if (date && date.length) {
      const [createdStart, createdEnd] = date;
      params.createdStart = createdStart.startOf('day').unix();
      params.createdEnd = createdEnd.endOf('day').unix();
      delete params.date;
    }

    setQueryData((origin) => ({
      ...origin,
      ...params,
      pageNum: 0,
    }));
  };

  const onChange = (pagination) => {
    setQueryData((origin) => ({
      ...origin,
      pageNum: pagination.current - 1,
      pageSize: pagination.pageSize,
    }));
  };

  useEffect(() => {
    if (!state.isAuth) return;
    if (!state.user?.is_admin) {
      navigate('/403');
      return;
    }
    handleGetLogList(queryData);
  }, [state.isAuth, queryData]);
  return (
    <>
      {/* Filter */}
      <div>
        <Form
          form={form}
          onFinish={onFilterSubmit}
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
              <Form.Item
                name="date"
                label={MESSAGES.LOG.RANGE_DATE}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 5 }}>
              <Form.Item
                name="type"
                label={MESSAGES.LOG.TYPE}
              >
                <Select>
                  {
                    logTypeList.map(
                      (item) => (
                        <Select.Option
                          value={item.value}
                          key={item.value}
                        >
                          {item.name}
                        </Select.Option>
                      ),
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 5 }}>
              <Form.Item
                name="operation"
                label={MESSAGES.LOG.OPERATION}
              >
                <Select>
                  {
                    logOperationList.map(
                      (item) => (
                        <Select.Option
                          value={item.value}
                          key={item.value}
                        >
                          {item.name}
                        </Select.Option>
                      ),
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }} style={{ textAlign: 'right' }}>
              <Space>
                <Button type="primary" htmlType="submit" disabled={tableLoading}>
                  {MESSAGES.COMMON.SEARCH}
                </Button>
                <Button htmlType="button" onClick={onReset} disabled={tableLoading}>
                  {MESSAGES.COMMON.RESET}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </div>

      {/* Table content */}
      <Table
        bordered
        loading={tableLoading}
        columns={columns}
        dataSource={logList}
        rowKey="id"
        onChange={onChange}
        pagination={{
          total,
          showSizeChanger: false,
          pageSize: queryData.pageSize,
          current: queryData.pageNum + 1,
          showTotal: (totalNum, range) => MESSAGES.COMMON.TOTAL(totalNum, range),
        }}
      />
    </>
  );
}

export default Log;
