import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Spin,
  Input, Select,
} from 'antd';
import { MESSAGES } from '../common';

function AddUser({
  isModalOpen,
  handleModalStatus,
  handleAddUser,
  modalLoading,
}) {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    handleModalStatus(false);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    const roles = values.roles.split(',');
    handleAddUser({ ...values, roles }, handleCancel);
  };

  return (
    <Modal
      open={isModalOpen}
      title={MESSAGES.COMMON.CREATE + MESSAGES.LABEL.USER}
      cancelText={MESSAGES.COMMON.CANCEL}
      okText={MESSAGES.COMMON.SAVE}
      onCancel={handleCancel}
      onOk={handleSave}
    >
      <Spin spinning={modalLoading}>
        <Form
          form={form}
          preserve={false}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            roles: 'general_user',
          }}
        >
          <Form.Item
            label={MESSAGES.LABEL.USER_NAME}
            name="name"
            rules={[
              {
                required: true,
                message: MESSAGES.ERROR.REQUIRED,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={MESSAGES.LABEL.EMAIL}
            name="email"
            rules={[
              {
                required: true,
                message: MESSAGES.ERROR.REQUIRED,
              },
              {
                type: 'email',
                message: MESSAGES.ERROR.EMAIL_INVALID,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={MESSAGES.LABEL.WWID}
            name="wwid"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={MESSAGES.LABEL.ROLE}
            name="roles"
            rules={[
              {
                required: true,
                message: MESSAGES.ERROR.REQUIRED,
              },
            ]}
          >
            <Select>
              <Select.Option value="general_user">User</Select.Option>
              <Select.Option value="admin">Admin</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

AddUser.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalStatus: PropTypes.func.isRequired,
  handleAddUser: PropTypes.func.isRequired,
  modalLoading: PropTypes.bool.isRequired,
};

export default AddUser;
