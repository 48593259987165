import React, {
  useReducer, useMemo, useEffect, useState, useCallback,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Layout, Spin, ConfigProvider,
  message as Message,
} from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import {
  MESSAGES,
  mainReducer,
  AppContext,
  initialState,
  useQuery,
  changeListDataFormat,
  getMyInfo,
  getFolderList,
  getUserList,
  authLogout,
  authSSOLogin,
  getFooter,
  getCsrfToken,
  redirectToSSO,
  debounce,
  checkTimeOut,
  updateLastActiveTime,
} from './common';
import { AppHeader, AppFooter } from './components';

function App() {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const appStateValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const code = useQuery().get('code') || '';
  const [activePath, setActivePath] = useState('');
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState('');

  const handleGetFooter = async () => {
    const { data } = await getFooter();
    setFooter(data.result || '');
  };

  const handleLogout = async () => {
    await authLogout();
    Message.success(MESSAGES.SUCCESS.LOGOUT_SUCCESS);
    dispatch({ type: 'logout' });
    redirectToSSO();
  };

  const getUserInfo = async () => {
    const user = await getMyInfo();
    dispatch({ type: 'userInfo', payload: { user } });
  };

  const getBlobList = async () => {
    const blobs = await getFolderList({ id: 0, file: 0 });
    dispatch({ type: 'blobs', payload: { blobs: changeListDataFormat(blobs) } });
  };

  const handleGetUserList = async () => {
    const { data } = await getUserList();
    dispatch({ type: 'userList', payload: { userList: data.result } });
  };

  const getAppData = async () => {
    try {
      setLoading(true);
      if (!state.user) await getUserInfo();
      if (!state.blobs) await getBlobList();
      if (!state.userList && state.user?.roles === 'admin') await handleGetUserList();
      if (!footer) await handleGetFooter();
    } finally {
      setLoading(false);
    }
  };

  const getTokens = async () => {
    setLoading(true);
    try {
      const data = new FormData();
      const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env;
      data.append('grant_type', 'authorization_code');
      data.append('client_id', REACT_APP_CLIENT_ID);
      data.append('client_secret', REACT_APP_CLIENT_SECRET);
      data.append('code', code);
      const {
        access_token: accessToken,
        expires_in: expiresIn,
      } = await authSSOLogin(data);
      const cdrfToken = await getCsrfToken();
      const now = Date.now();
      const expires = now + expiresIn * 1000;
      dispatch({ type: 'token', payload: { accessToken, expires, cdrfToken } });
      navigate('/home');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setActivePath(pathname);
    if (state.isAuth) {
      if (pathname === '/') { // Navigate to Home page
        navigate('/home');
      }
      getAppData();
    } else if (code) {
      getTokens();
    } else {
      // Navigate to Backend Login page if not auth
      redirectToSSO();
      // navigate('/login');
    }
  }, [state.isAuth, pathname, state.user]);

  const setLastActiveDate = debounce(updateLastActiveTime);

  useEffect(() => {
    updateLastActiveTime();
    document.body.addEventListener('mousedown', setLastActiveDate);
    const intervalCheck = window.setInterval(
      checkTimeOut.bind(null, authLogout, dispatch),
      30 * 1000,
    );

    return () => {
      window.removeEventListener('mousedown', setLastActiveDate);
      if (intervalCheck) {
        clearInterval();
      }
    };
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <AppContext.Provider value={appStateValue}>
        <Spin spinning={loading}>
          <Layout className="app-layout">
            <AppHeader
              username={state.user?.name || ''}
              userRole={state.user?.roles || ''}
              activePath={activePath}
              onLogout={handleLogout}
            />
            <Layout.Content>
              <Outlet />
            </Layout.Content>
            <AppFooter footer={footer} />
          </Layout>
        </Spin>
      </AppContext.Provider>
    </ConfigProvider>
  );
}

export default App;
