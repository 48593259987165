import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Button, Table, Space, Modal,
  message as Message,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { PermissionFilter, AddPermission } from '../components';
import {
  MESSAGES,
  AppContext,
  getFolderList,
  getPermissionList,
  createPermissions,
  updatePermissions,
  deletePermissions,
  changeListDataFormat,
  iconsMap,
  updateTreeData,
  getIconByPermission,
} from '../common';

import styles from '../scss/modules/permission.module.scss';

function Roles() {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [permissionList, setPermissionList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [formType, setFormType] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [foldersTreeData, setFoldersTreeData] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryData, setQueryData] = useState({
    blob: '',
    folder: '',
    user: '',
    role: '',
    pageNum: 0,
    pageSize: 10,
  });

  const columns = [
    {
      title: MESSAGES.LABEL.USER,
      dataIndex: 'user',
      align: 'center',
    },
    {
      title: MESSAGES.LABEL.ROLE,
      dataIndex: 'role',
      align: 'center',
    },
    {
      title: MESSAGES.COMMON.BLOB,
      dataIndex: 'blob',
      align: 'center',
    },
    {
      title: MESSAGES.COMMON.CONTAINER,
      // dataIndex: 'folder',
      dataIndex: 'path',
      key: 'path',
      align: 'center',
    },
    {
      title: MESSAGES.LABEL.UPLOAD_PERMISSION,
      dataIndex: 'upload_permission',
      align: 'center',
      render: (_, { upload_permission: perms }) => getIconByPermission(perms, iconsMap),
    },
    {
      title: MESSAGES.LABEL.DOWNLOAD_PERMISSION,
      dataIndex: 'download_permission',
      align: 'center',
      render: (_, { download_permission: perms }) => getIconByPermission(perms, iconsMap),
    },
    {
      title: MESSAGES.LABEL.DELETE_PERMISSION,
      dataIndex: 'delete_permission',
      align: 'center',
      render: (_, { delete_permission: perms }) => getIconByPermission(perms, iconsMap),
    },
    {
      title: MESSAGES.COMMON.FILE_CONTROL,
      dataIndex: 'file_control',
      align: 'center',
    },
  ];

  const clearTreeData = () => {
    setFoldersTreeData([]);
  };

  const handleGetFolderList = async (data, isBlobChange) => {
    const { id } = data;
    try {
      const list = await getFolderList({ ...data });
      const folders = changeListDataFormat(list, { iconsMap });

      if (id === 0) {
        dispatch({
          type: 'blobs',
          payload: { blobs: folders },
        });
      } else if (isBlobChange) {
        setFoldersTreeData(folders);
      } else {
        setFoldersTreeData((origin) => updateTreeData(origin, id, folders));
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleBlobChange = (id, file) => {
    // Reset folder tree data
    handleGetFolderList({ id, file }, true);
  };

  const handleGetPermissionList = async (params = {}) => {
    setTableLoading(true);
    try {
      const { data } = await getPermissionList({
        ...queryData,
        ...params,
      });
      setPermissionList(data.result);
      setTotal(data.total);
      setSelectedRowKeys([]);
    } catch (error) {
      // Handle error
    } finally {
      setTableLoading(false);
    }
  };

  const handleUpdatePermission = async (data, callback) => {
    let apiData = data;
    let $targetAPI = null;
    let msg = MESSAGES.SUCCESS.CREATE_SUCCESS;
    let pageIndex = queryData.pageNum;
    delete apiData.permissions;

    if (formType === 'add') {
      $targetAPI = createPermissions;
      pageIndex = 0;
    } else {
      $targetAPI = updatePermissions;
      msg = MESSAGES.SUCCESS.UPDATE_SUCCESS;
      apiData = {
        id: selectedRowKeys.join(','),
        permissions: data,
      };
    }

    if (!$targetAPI) {
      return;
    }

    setModalLoading(true);
    try {
      await $targetAPI(apiData);
      Message.success(msg);
      setQueryData((origin) => ({ ...origin, pageNum: pageIndex }));
      if (callback) {
        callback();
      }
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: pageIndex }));
      }
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeletePermission = async () => {
    setTableLoading(true);
    try {
      await deletePermissions(selectedRowKeys.join(','));
      Message.success(MESSAGES.SUCCESS.DELETE_SUCCESS);
      setQueryData((origin) => ({ ...origin, pageNum: 0 }));
    } catch (error) {
      // Handle error
      if (error?.response?.data?.refreshList) {
        setQueryData((origin) => ({ ...origin, pageNum: 0 }));
      }
    } finally {
      setTableLoading(false);
    }
  };

  const handleDeleteConfirm = () => {
    Modal.confirm({
      title: MESSAGES.COMMON.DELETE_CONFIRM,
      okText: MESSAGES.COMMON.CONFIRM,
      cancelText: MESSAGES.COMMON.CANCEL,
      onOk() {
        handleDeletePermission();
      },
    });
  };

  const handleModalStatus = (status, type) => {
    setIsModalOpen(status);
    setFormType(type || '');
  };

  const onChange = (pagination) => {
    setQueryData((origin) => ({
      ...origin,
      pageSize: pagination.pageSize,
      pageNum: pagination.current - 1,
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  useEffect(() => {
    if (!state.isAuth) return;
    if (!state.user?.is_admin) {
      navigate('/403');
      return;
    }

    handleGetPermissionList(queryData);
  }, [state.isAuth, queryData]);

  return (
    <>
      <div className={`${styles.filterSection} filter-section`}>
        <PermissionFilter
          userList={state.userList || []}
          blobs={state.blobs || []}
          foldersTreeData={foldersTreeData}
          loading={tableLoading}
          handleBlobChange={handleBlobChange}
          handleGetFolderList={handleGetFolderList}
          clearTreeData={clearTreeData}
          setQueryData={setQueryData}
        />
      </div>
      <Space>
        <Button type="primary" onClick={() => handleModalStatus(true, 'add')}>
          {MESSAGES.COMMON.CREATE}
        </Button>
        <Button
          type="primary"
          onClick={() => handleModalStatus(true, 'update')}
          disabled={!selectedRowKeys.length}
        >
          {MESSAGES.COMMON.UPDATE}
        </Button>
        <Button
          type="primary"
          onClick={handleDeleteConfirm}
          disabled={!selectedRowKeys.length}
        >
          {MESSAGES.COMMON.DELETE}
        </Button>
      </Space>
      <div className="table">
        <Table
          bordered
          rowSelection={{
            ...rowSelection,
          }}
          loading={tableLoading}
          rowKey="id"
          columns={columns}
          dataSource={permissionList}
          onChange={onChange}
          pagination={{
            showSizeChanger: false,
            current: queryData.pageNum + 1,
            pageSize: queryData.pageSize,
            total,
            showTotal: (totalNum, range) => MESSAGES.COMMON.TOTAL(totalNum, range),
          }}
        />
      </div>

      <AddPermission
        isModalOpen={isModalOpen}
        handleModalStatus={handleModalStatus}
        modalLoading={modalLoading}
        userList={state.userList}
        formType={formType}
        handleUpdatePermission={handleUpdatePermission}
        blobs={state.blobs || []}
        handleGetFolderList={handleGetFolderList}
      />
    </>
  );
}

export default Roles;
