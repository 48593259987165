import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Select, TreeSelect,
  Button, Space, Row, Col,
} from 'antd';
import {
  MESSAGES,
} from '../common';

function PermissionFilter({
  userList,
  blobs,
  foldersTreeData,
  loading,
  handleBlobChange,
  handleGetFolderList,
  clearTreeData,
  setQueryData,
}) {
  const [form] = Form.useForm();

  const handleSetQueryData = (values) => {
    setQueryData((origin) => ({
      ...origin,
      ...values,
      pageNum: 0,
    }));
  };

  const onReset = () => {
    form.resetFields();
    clearTreeData();
    handleSetQueryData(form.getFieldsValue());
  };

  const onLoadData = async (node) => {
    handleGetFolderList({ id: node.id, file: 0 });
  };

  const renderUserList = userList.map(
    (item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>,
  );

  const blobChange = (value) => {
    form.resetFields(['folder']);
    clearTreeData();
    handleBlobChange(value, 0);
  };

  return (
    <Form
      form={form}
      onFinish={handleSetQueryData}
    >
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Item
            name="blob"
            label={MESSAGES.COMMON.BLOB}
          >
            <Select onChange={blobChange}>
              {
                blobs.map(
                  (item) => (
                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                  ),
                )
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
          <Form.Item
            name="folder"
            label={MESSAGES.COMMON.CONTAINER}
          >
            <TreeSelect
              treeIcon
              treeData={foldersTreeData}
              loadData={onLoadData}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 4 }}>
          <Form.Item
            label={MESSAGES.LABEL.USER}
            name="user"
          >
            <Select>
              {renderUserList}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 4 }}>
          <Form.Item
            label={MESSAGES.LABEL.ROLE}
            name="role"
          >
            <Select>
              <Select.Option value="user">User</Select.Option>
              <Select.Option value="admin">Admin</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 3 }} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {MESSAGES.COMMON.SEARCH}
            </Button>
            <Button htmlType="button" onClick={onReset} disabled={loading}>
              {MESSAGES.COMMON.RESET}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

const userShap = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const folderShap = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

PermissionFilter.propTypes = {
  loading: PropTypes.bool.isRequired,
  userList: PropTypes.arrayOf(userShap).isRequired,
  blobs: PropTypes.arrayOf(folderShap).isRequired,
  foldersTreeData: PropTypes.arrayOf(folderShap).isRequired,
  handleBlobChange: PropTypes.func.isRequired,
  handleGetFolderList: PropTypes.func.isRequired,
  clearTreeData: PropTypes.func.isRequired,
  setQueryData: PropTypes.func.isRequired,
};

export default PermissionFilter;
