/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import App from './App';
import {
  Home, Users, Roles, FilesManagement, ErrorPage, Log,
} from './pages';
import './scss/index.scss';
import reportWebVitals from './reportWebVitals';

// const jih_domain = process.env.VUE_APP_JIH_DOMAIN;
// VUE_APP_JIH_DOMAIN = https://identity.janssenpro.com.cn

const routes = [
  {
    element: <App />,
    path: '/',
    children: [
      {
        index: true,
        path: 'home',
        element: <Home />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'roles',
        element: <Roles />,
      },
      {
        path: 'files-management',
        element: <FilesManagement />,
      },
      {
        path: 'log',
        element: <Log />,
      },
      {
        path: ':result',
        element: <ErrorPage />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
];

const Router = () => useRoutes(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
