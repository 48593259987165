import React from 'react';
import { Layout, Tree } from 'antd';
import PropTypes from 'prop-types';
import { ExclamationOutlined } from '@ant-design/icons';
import styles from '../scss/modules/sidebar.module.scss';
import { MESSAGES } from '../common';

function FileTree({
  treeData,
  selectedKeys,
  loadedKeys,
  onSelect,
  onLoadData,
  fileTreeKey,
}) {
  const titleRender = ({ name, new_file: hasNewFile }) => (
    <span>
      {
        !!hasNewFile && <ExclamationOutlined className="new-file-tag" />
      }
      {name}
    </span>
  );

  return (
    <Layout.Sider
      className={styles.sidebar}
      width={350}
    >
      {
        treeData.length ? (
          <Tree
            className={styles.fileTree}
            key={fileTreeKey}
            showIcon
            height={700}
            treeData={treeData}
            selectedKeys={selectedKeys}
            loadedKeys={loadedKeys}
            onSelect={onSelect}
            loadData={onLoadData}
            titleRender={titleRender}
          />
        ) : <div style={{ textAlign: 'center' }}>{MESSAGES.HOME.NODATA}</div>
      }
    </Layout.Sider>
  );
}

const treeDataType = {
  title: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};
treeDataType.children = PropTypes.arrayOf(PropTypes.shape(treeDataType));

const treeDataShape = PropTypes.shape(treeDataType);

FileTree.propTypes = {
  treeData: PropTypes.arrayOf(treeDataShape).isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
  fileTreeKey: PropTypes.string.isRequired,
};

export default FileTree;
