import { createContext } from 'react';
import {
  setLocalAccessToken,
  setLocalExpires,
  setLocalUserInfo,
  isExpiresIn,
  clearLocalStorage,
  getLocalUserInfo,
  setLocalCsrfToken,
} from './util';

export const initialState = {
  isAuth: !isExpiresIn(),
  user: getLocalUserInfo() || null,
  blobs: null,
  userList: null,
};

export const AppContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export const mainReducer = (state, { type, payload }) => {
  switch (type) {
    case 'token':
      setLocalAccessToken(payload.accessToken);
      setLocalExpires(payload.expires);
      setLocalCsrfToken(payload.cdrfToken);
      return { ...state, isAuth: true };
    case 'userInfo':
      setLocalUserInfo(payload.user);
      return { ...state, ...payload };
    case 'logout':
      clearLocalStorage();
      return {
        isAuth: false,
        user: null,
        blobs: null,
        userList: null,
      };
    case 'blobs':
      return { ...state, ...payload };
    case 'userList':
      return { ...state, ...payload };
    default:
      return state;
  }
};
