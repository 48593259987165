import {
  FileOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

const iconsMap = new Map([
  ['blob', FolderOutlined],
  ['blobOpen', FolderOpenOutlined],
  ['folder', FolderOutlined],
  ['folderOpen', FolderOpenOutlined],
  ['file', FileOutlined],
  ['checked', CheckOutlined],
  ['close', CloseOutlined],
]);

export default iconsMap;
