import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Menu,
  Breadcrumb,
  Modal,
  Spin,
  Form,
  Input,
  Space,
} from 'antd';
import {
  DownOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { MESSAGES, AppContext } from '../common';
import styles from '../scss/modules/options.module.scss';

function HomeOptions({
  blobList,
  paths,
  modalLoading,
  onClickBlob,
  onClickPath,
  onDelete,
  onCreate,
  actionPermission,
}) {
  const defaultMessage = blobList.length ? MESSAGES.HOME.SELECT : MESSAGES.HOME.NODATA;
  const selectedKey = paths.length ? paths[0].key : null;
  const selectedName = paths.length ? paths[0].title : defaultMessage;
  const [form] = Form.useForm();
  const [createType, setCreateType] = useState(null);
  const { state } = useContext(AppContext);
  const isAdmin = state.user && !!state.user?.is_admin;

  const [blobDropdownList, setBlobDropdownList] = useState([]);

  const handleFolderAction = ({ type, action, id = '' }) => {
    if (action === 'delete') {
      Modal.confirm({
        title: MESSAGES.COMMON.DELETE + MESSAGES.COMMON[type],
        icon: <ExclamationCircleOutlined />,
        okText: MESSAGES.COMMON.CONFIRM,
        cancelText: MESSAGES.COMMON.CANCEL,
        zIndex: 2000,
        onOk: () => {
          onDelete({ root: type === 'BLOB', blobDeleteId: `${id}` });
        },
      });
    } else {
      setCreateType(type);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setCreateType(null);
  };

  const handleSave = async () => {
    if (createType === 'BLOB') {
      const { blob: name } = await form.validateFields();
      onCreate({ pid: 0, name, node: {} }, handleCancel);
    } else {
      const { folder: name } = await form.validateFields();
      const [{ id: pid }] = paths.slice(-1);
      onCreate({ pid, name, node: paths.slice(-2)[0] }, handleCancel);
    }
  };

  const blobMenu = (
    <Menu
      onClick={onClickBlob}
      items={blobDropdownList}
      selectedKeys={[selectedKey]}
    />
  );

  const breadcrumbs = paths.map(({
    key, title, id,
  }, index) => (
    <Breadcrumb.Item
      className={(index < paths.length - 1) ? styles.breadcrumb : ''}
      key={key}
      onClick={
        (index < paths.length - 1) ? () => onClickPath({ key, id, index }) : undefined
      }
    >
      {title}
    </Breadcrumb.Item>
  ));

  const folderActions = (
    <div className={styles.buttons}>
      <Button
        disabled={!actionPermission.folderUpload}
        icon={<PlusOutlined />}
        onClick={() => handleFolderAction({ action: 'add', type: 'FOLDER' })}
        title={MESSAGES.COMMON.CREATE + MESSAGES.COMMON.FOLDER}
      >
        {MESSAGES.COMMON.CREATE + MESSAGES.COMMON.FOLDER}
      </Button>
      <Button
        icon={<DeleteOutlined />}
        disabled={!actionPermission.folderDelete}
        onClick={() => handleFolderAction({ action: 'delete', type: 'FOLDER' })}
        title={MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FOLDER}
      >
        {MESSAGES.COMMON.DELETE + MESSAGES.COMMON.FOLDER}
      </Button>
    </div>
  );

  useEffect(() => {
    if (isAdmin && blobList.length) {
      setBlobDropdownList([
        {
          type: 'group',
          className: styles.blobAction,
          children: [
            {
              key: 'blob-action',
              label: (
                <Button
                  title={MESSAGES.COMMON.CREATE + MESSAGES.COMMON.BLOB}
                  icon={<PlusOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFolderAction({ action: 'add', type: 'BLOB' });
                  }}
                >
                  {MESSAGES.COMMON.BLOB}
                </Button>
              ),
            },
          ],
        },
        {
          type: 'divider',
        },
        ...blobList.map((item) => ({
          ...item,
          label: (
            <div className={styles.blobListWithDelete}>
              <span className="blob-title">
                {item.title}
              </span>
              <Button
                type="text"
                title={MESSAGES.COMMON.DELETE + MESSAGES.COMMON.BLOB}
                icon={<DeleteOutlined />}
                disabled={!item.delete_permission}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFolderAction({ action: 'delete', type: 'BLOB', id: item.id });
                }}
              />
            </div>
          ),
        })),
      ]);
    } else {
      setBlobDropdownList(blobList);
    }
  }, [blobList, isAdmin]);

  return (
    <div className={styles.options}>
      <div className={styles.blobs}>
        {
          blobList.length ? (
            <Dropdown
              placement="bottom"
              overlay={blobMenu}
              disabled={!blobList.length}
            >
              <Space className={styles.name}>
                <span>
                  {MESSAGES.COMMON.BLOB}
                  :
                </span>
                {selectedName}
                <DownOutlined />
              </Space>
            </Dropdown>
          ) : (
            <div>
              <Button
                icon={<PlusOutlined />}
                disabled={!isAdmin}
                onClick={() => setCreateType('BLOB')}
                title={MESSAGES.COMMON.CREATE + MESSAGES.COMMON.BLOB}
              >
                {MESSAGES.COMMON.CREATE + MESSAGES.COMMON.BLOB}
              </Button>
            </div>
          )
        }
      </div>
      <div className={styles.container}>
        <Breadcrumb>
          <Breadcrumb.Item>
            {`${MESSAGES.COMMON.PATH}: `}
          </Breadcrumb.Item>
          {breadcrumbs}
        </Breadcrumb>
        {/* Folder Action */}
        {isAdmin && folderActions}
      </div>
      <Modal
        open={!!createType}
        title={MESSAGES.COMMON.CREATE + MESSAGES.COMMON[createType]}
        cancelText={MESSAGES.COMMON.CANCEL}
        okText={MESSAGES.COMMON.SAVE}
        onCancel={handleCancel}
        onOk={handleSave}
        zIndex={2000}
        cancelButtonProps={{
          disabled: modalLoading,
        }}
        okButtonProps={{
          disabled: modalLoading,
          loading: modalLoading,
        }}
      >
        <Spin spinning={modalLoading}>
          <Form
            form={form}
            preserve={false}
            layout="vertical"
          >
            {createType === 'BLOB' && (
              <>
                <Form.Item
                  label={MESSAGES.COMMON[createType] + MESSAGES.LABEL.NAME}
                  name="blob"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ERROR.REQUIRED,
                    },
                  ]}
                >
                  <Input autoComplete="off" placeholder={MESSAGES.COMMON.BLOB_NAME_LIMITED} />
                </Form.Item>
                <Form.Item
                  style={{
                    color: '#00000073',
                  }}
                >
                  {`* ${MESSAGES.COMMON.BLOB_NAME_TIPS}`}
                </Form.Item>
              </>
            )}
            {createType === 'FOLDER' && (
              <>
                <div className={styles.filePath}>
                  <span>{`${MESSAGES.COMMON.PATH}: `}</span>
                  {paths.map(({ title, id }) => (
                    <span key={id}>
                      {`/${title}`}
                    </span>
                  ))}
                </div>
                <Form.Item
                  label={MESSAGES.COMMON[createType] + MESSAGES.LABEL.NAME}
                  name="folder"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ERROR.REQUIRED,
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </>
            )}
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});
HomeOptions.propTypes = {
  blobList: PropTypes.arrayOf(itemShape).isRequired,
  paths: PropTypes.arrayOf(itemShape).isRequired,
  modalLoading: PropTypes.bool.isRequired,
  onClickBlob: PropTypes.func.isRequired,
  onClickPath: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  actionPermission: PropTypes.shape({
    blobDelete: PropTypes.bool.isRequired,
    folderDelete: PropTypes.bool.isRequired,
    folderUpload: PropTypes.bool.isRequired,
  }).isRequired,
};

export default HomeOptions;
