import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Spin, Upload,
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  MESSAGES,
  UPLOAD_CHUNK_SIZE,
  UPLOAD_MAX_SIZE,
  humanFileSize,
  debounce,
} from '../common';
import styles from '../scss/modules/upload.module.scss';

function MultipleUpload({
  open,
  loading,
  spinningTip,
  setOpenModal,
  onSmallFileUpload,
  onLargeFileUpload,
}) {
  const [uploadList, setUploadList] = useState([]);
  const [validFileList, setvalidFileList] = useState([]);
  const [largeFileUpload, setLargeFileUpload] = useState(false);
  const [uploadTips, setUploadTips] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const limitedSize = humanFileSize(UPLOAD_CHUNK_SIZE);
  const maxFileSize = humanFileSize(UPLOAD_MAX_SIZE);
  const maxCount = 100;

  const onCancel = () => {
    setOpenModal(false);
    setUploadList([]);
    setErrorMsg('');
    setUploadTips('');
  };

  const onSave = () => {
    let msg = '';
    if (largeFileUpload) {
      const file = uploadList[0];
      if (file.size > UPLOAD_MAX_SIZE) {
        msg = MESSAGES.COMMON.BACK_UPLOAD_MAX_SIZE(maxFileSize);
      } else {
        onLargeFileUpload(file, onCancel);
      }
    } else if (!validFileList.length) {
      msg = MESSAGES.ERROR.BATCH_UPLOAD_EMPTY(limitedSize);
    } else {
      onSmallFileUpload(validFileList, onCancel);
    }

    if (msg) {
      setErrorMsg(msg);
    }
  };

  const handleBeforeUpload = (_, fileList) => {
    let uploadedList = uploadList.slice(0);
    for (let i = 0; i < fileList.length; i += 1) {
      if (uploadedList.length >= maxCount) {
        break;
      }
      const fileInfo = fileList[i];
      const { size, name } = fileInfo;
      fileInfo.sizeValid = size < UPLOAD_CHUNK_SIZE;
      const index = uploadedList.findIndex((file) => file.name === name);
      if (index > -1) {
        uploadedList.splice(index, 1, fileInfo);
      } else {
        uploadedList = [...uploadedList, fileInfo];
      }
    }

    setUploadList(uploadedList);
    return false;
  };

  const handleRemove = (file) => {
    const index = uploadList.indexOf(file);
    const uploadedList = uploadList.slice();
    uploadedList.splice(index, 1);
    setUploadList(uploadedList);
  };

  useEffect(() => {
    const validFiles = uploadList.filter((item) => item.sizeValid);
    setvalidFileList(validFiles);
    setLargeFileUpload(false);
    setUploadTips('');
    setErrorMsg('');

    if (uploadList.length === 1 && validFiles.length === 0) {
      setLargeFileUpload(true);
    } else if (uploadList.length && validFiles.length < uploadList.length) {
      setUploadTips(MESSAGES.ERROR.BATCH_UPLOAD_TIPS(limitedSize));
    }
  }, [uploadList]);

  return (
    <Modal
      open={open}
      title={MESSAGES.COMMON.BATCH_UPLOAD_FILE}
      onCancel={onCancel}
      onOk={onSave}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      className={styles.multipleUpload}
    >
      <Spin spinning={loading} tip={spinningTip}>
        <div className="msg-popup-tips">
          {`* ${MESSAGES.COMMON.BATCH_UPLOAD_CHUNK_LIMITED(limitedSize)}`}
          <br />
          {`* ${MESSAGES.COMMON.BATCH_UPLOAD_MAX_COUNT(maxCount, limitedSize)}`}
          <br />
          {`* ${MESSAGES.COMMON.BACK_UPLOAD_MAX_SIZE(maxFileSize)}`}
        </div>
        <Upload
          showUploadList={false}
          maxCount={maxCount}
          onRemove={handleRemove}
          beforeUpload={debounce(handleBeforeUpload)}
          fileList={uploadList}
          customRequest={() => {}}
          multiple
        >
          <Button
            icon={<UploadOutlined />}
            disabled={uploadList.length >= maxCount}
            title={MESSAGES.COMMON.UPLOAD + MESSAGES.COMMON.FILE}
          >
            {MESSAGES.COMMON.UPLOAD + MESSAGES.COMMON.FILE}
          </Button>
        </Upload>
        <div className={styles.uploadList}>
          {
            !!uploadList.length && uploadList.map((file, index) => {
              const { size } = file;
              const sizeValid = size <= UPLOAD_CHUNK_SIZE;
              return (
                <div
                  key={file.uid}
                  className={`file-item ${sizeValid ? 'size-valid' : 'size-valid-error'}`}
                >
                  <span className="file-index">{`${index + 1}. `}</span>
                  <span className="file-name">{`${file.name} (${humanFileSize(size)})`}</span>
                  <span className="file-action">
                    <Button
                      type="text"
                      onClick={() => handleRemove(file)}
                      icon={<DeleteOutlined />}
                    />
                  </span>
                </div>
              );
            })
          }
        </div>
        {
          uploadTips && (<div className="msg msg-tips">{uploadTips}</div>)
        }
        {
          errorMsg && (<div className="msg msg-error">{errorMsg}</div>)
        }
      </Spin>
    </Modal>
  );
}

MultipleUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onSmallFileUpload: PropTypes.func.isRequired,
  onLargeFileUpload: PropTypes.func.isRequired,
  spinningTip: PropTypes.node.isRequired,
};

export default MultipleUpload;
