export const MESSAGES = {
  LABEL: {
    EMAIL: '邮箱',
    USER: '用户',
    USER_NAME: '用户名',
    WWID: 'WWID',
    ROLE: '角色',
    NAME: '名称',
    FOLDER_TYPE: '类型',
    PERMISSION: '权限',
    UPLOAD_PERMISSION: '上传权限',
    DOWNLOAD_PERMISSION: '下载权限',
    DELETE_PERMISSION: '删除权限',
    LOGIN: '登录',
    LOGIN_TITLE: '请先登录站点',
  },
  COMMON: {
    SAVE: '保存',
    CREATE: '新建',
    ADD: '添加',
    CANCEL: '取消',
    DELETE: '删除',
    DELETE_CONFIRM: '确定要删除该数据吗？',
    CONFIRM: '确定',
    UPDATE: '修改',
    SEARCH: '检索',
    RESET: '重置',
    BATCH_UPLOAD_FILE: '批量上传文件',
    BATCH_UPLOAD_CHUNK_LIMITED: (limitedSize) => `大于${limitedSize}的文件请单独上传，列表中蓝色文件表示大于${limitedSize}的文件；`,
    BATCH_UPLOAD_MAX_COUNT: (maxCount, limitedSize) => `一次最多上传${maxCount}个文件, 且文件总大小不能超过${limitedSize}；`,
    BACK_UPLOAD_MAX_SIZE: (maxSize) => `单个大文件最大不超过${maxSize}`,
    UPLOAD_SNIP_TIPS: '文件正在上传中，请耐心等待',
    UPLOAD: '上传',
    UPLOAD_TIME: '上传时间',
    DOWNLOAD: '下载',
    FILE_NAME: '文件名',
    FILE_SIZE: '文件大小',
    FILE_CONTROL: '文件控制',
    ROLE_CONTROL: '权限控制',
    BLOB: 'Container', // Blob is the first level which named as 'container' in storage.
    BLOB_NAME_LIMITED: '建议名称长度小于25个字符',
    CONTAINER: 'Folder', // All sub levels under container (Blob) are called folder. (container/*/**/folder)
    FILE: '文件',
    FOLDER: '文件夹',
    TOTAL: (total, range) => `${range[0]} - ${range[1]} 条 ( 共 ${total} 条 )`,
    ACTION: '操作',
    PATH: '路径',
    EMPTY_DATA: '暂无数据',
    BLOB_NAME_TIPS: 'Container名称只能包含小写字母，数字和连字符 (-)，并且必须以字母或者数字开头和结尾。名称不能包含两个连续的连字符 (-)。',
    NEW_FILE_TIPS: '该文件暂未设置可操作用户，请前往权限管理页面添加权限。',
    BACK_TO_HOME: '返回首页',
  },
  SUCCESS: {
    DELETE_SUCCESS: '删除成功',
    CREATE_SUCCESS: '创建成功',
    UPDATE_SUCCESS: '修改成功',
    SAVE_SUCCESS: '保存成功',
    LOGOUT_SUCCESS: '退出登录成功',
    UPLOAD_SUCCESS: '上传成功',
  },
  ERROR: {
    REQUIRED: '该字段为必填字段',
    SELECT_REQUIRED: '该字段为必选字段',
    EMAIL_INVALID: '请输入正确的邮箱格式',
    UNAUTHORIZED: '登陆信息已过期，请重新登陆',
    UNKNOW_ERROR: '未知错误，请稍后再试',
    UPLOAD_SAME_NAME: '只能上传相同文件名的文件',
    UPLOAD_FOLDER_EMPTY: '请先选择文件夹再上传文件',
    BATCH_UPLOAD_EMPTY: (limitedSize) => `请选择一个大于${limitedSize}的文件或者多个小于${limitedSize}的文件进行上传`,
    BATCH_UPLOAD_TIPS: (limitedSize) => `此次上传将只上传小于${limitedSize}的文件，大于${limitedSize}的文件请单独上传`,
    SELECT_FOLDER_REQUIRED: '请先选择文件夹',
    NO_DOWNLOAD_PERMISSION: '所选的文件没有下载权限',
    PARTIAL_DOWNLOAD_PERMISSION: '将只下载有下载权限的文件',
    REQUEST_TIMEOUT: '请求超时，请稍后再试',
  },
  ERROR_PAGE: {
    403: '对不起，您没有访问该页面的权限！',
    500: '出错拉！',
    404: '对不起，您访问的页面不存在！',
  },
  HEADER: {
    LOGOUT: '退出登录',
    HOME: '首页',
    USER: '用户管理',
    ROLE: '权限管理',
    FILE_CONTROL: '文件控制',
    LOG: '日志',
  },
  HOME: {
    NODATA: '没有数据',
    SELECT: '请选择',
  },
  LOG: {
    TYPE: '功能页面',
    OPERATION: '操作',
    DESCRIPTION: '操作描述',
    CREATE_DATE: '创建时间',
    RANGE_DATE: '创建区间段',
    TYPE_LIST: {
      BLOB: 'Container',
      FOLDER: 'Folder',
      USER: '用户管理',
      PERMISSION: '权限控制',
      FILE: 'File',
      FILE_CONTROL: '文件控制',
    },
    ACTIONS: {
      CREATE: '新增',
      UPDATE: '修改',
      DELETE: '删除',
      DOWNLOAD: '下载',
    },
  },
};

export const MAX_REQUEST_COUNT = 6;

export const UPLOAD_CHUNK_SIZE = 70 * 1024 * 1024; // 35M

export const UPLOAD_MAX_SIZE = 2 * 1024 * 1024 * 1024; // 2G

export const MAX_EXPIRE_TIME = 15 * 60 * 1000;
