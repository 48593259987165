import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { MESSAGES } from '../common';

const error = {
  403: {
    title: '403',
    subTitle: MESSAGES.ERROR_PAGE[403],
  },
  500: {
    title: '500',
    subTitle: MESSAGES.ERROR_PAGE[500],
  },
  404: {
    title: '404',
    subTitle: MESSAGES.ERROR_PAGE[404],
  },
};

function ErrorPage() {
  const { result } = useParams();
  const [status, setStatus] = useState(404);

  useEffect(() => {
    let errorStatus = result;
    if (!errorStatus || !error[errorStatus]) {
      errorStatus = 404;
    }
    setStatus(errorStatus);
  }, [result]);

  return (
    <Result
      status={status}
      title={error[status].title}
      subTitle={error[status].subTitle}
      extra={
        <Link type="primary" to="/">{MESSAGES.COMMON.BACK_TO_HOME}</Link>
      }
    />
  );
}

export default ErrorPage;
